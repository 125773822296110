<template>
  <v-card :loading="loading">
    <v-card-title v-if="manifest" class="align-start">{{ location }} - {{ Display.DisplayDateDayInMonth(manifest.ManifestDate) }}</v-card-title>

    <v-card-subtitle class="mb-8 mt-n5" :class="(manifestStatus == 'Closed' ? 'error--text' : 'success--text')">Manifest {{ manifestStatus }}.</v-card-subtitle>

    <v-card-text v-if="manifest">
      <v-row>
        <v-col v-for="item in orderedItems" :key="item.Title" cols="6" class="d-flex align-center">
          <v-avatar rounded class="elevation-1" size="44" :color="resolveColor(item.Title).color">
            <v-icon dark color="white" size="30">
              {{ resolveColor(item.Title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ item.Title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ item.Total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiArchive,
  mdiArchiveOutline,
  mdiForklift
} from '@mdi/js'

import { ref, computed, onMounted, onUnmounted, reactive   } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'

export default {
  props: {
    location: {
      type: String,
      required: true
    }
  },
  setup(props) {

    const { router  } = useRouter();

    const manifestsData = reactive(store.state.app.DashboardManifests);
    const manifestData = computed(() => {
      if (manifestsData && manifestsData[props.location]) {
        return manifestsData[props.location];
      }
      return null;
    });

    const manifest = computed(() => {
      if (manifestData.value) {
        return manifestData.value.manifest;
      }
      return null;
    });

    const items = computed(() => {
      if (manifestData.value) {
        return manifestData.value.stats;
      }
      return [];
    });

    const manifestStatus = computed(() => {
      if (manifest && manifest.value) {
        if (manifest.value.Closed) {
          return 'Closed';
        } else {
          return 'Open';
        };
      }
      return '';
    });

    const orderedItems = computed(() => {
      if (items.value) {
        return items.value.reverse();
      }
      return [];
    });

    const loading = ref(true);

    const fetchData = () => {
      store.dispatch('app/fetchManifestDashboard', {
        location: props.location
      })
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
    }
    fetchData();

    // fetch data periodically
    let intervalId;
    onMounted(() => {
      intervalId = setInterval(() => {
        fetchData();
      }, 58000); // every minute
    })
    onUnmounted(() => clearInterval(intervalId))


    const resolveColor = data => {
      if (data === 'Ready') return { icon: mdiArchive, color: 'success' }
      if (data === 'Manifest') return { icon: mdiForklift, color: 'primary' }

      return { icon: mdiArchiveOutline, color: 'secondary' }
    }

    return {
      Display,
      orderedItems,
      items,
      manifest,
      manifestStatus,
      resolveColor,
      loading
    }
  },
}
</script>
