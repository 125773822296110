/* Order List, Vue Component */
<template>
  <v-card id="order-list">
    <v-card-title v-if="title">{{ title }}</v-card-title>

    <v-card-text v-if="!hideFilter" class="d-flex align-center flex-wrap pb-0">

   </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: item.Id } }">
        {{ item.Reference }}
      </router-link>
    </template>

    <template #[`item.MemberId_Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: item.MemberId } }">
        {{ item.MemberId_Reference }}
      </router-link>
    </template>

    <template #[`item.StatusId_Name`]="{item}">
      <order-status-chip :status="item.StatusId_Name"></order-status-chip>
    </template>

    <template #[`item.ProductId_Name`]="{item}">
      <div v-if="item.BespokePrice">
        <v-chip title="Custom Shipment" small color="info" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiCog }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
      <div v-else-if="item.ProductId_Name">
        {{ item.ProductId_Code}} - {{ item.ProductId_Name}}
      </div>
      <div v-else class="caption d-flex">
         <v-chip title="Quote" small color="warning" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiBookEditOutline }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
    </template>

    <template #[`item.Updated`]="{item}">
      {{ Display.DateTime(item.Updated) }}
    </template>


    <template #[`item.DeliveryAddressId_Summary`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.DeliveryAddressId_Summary }}</div>
        </template>
        <span>{{ item.DeliveryAddressId_Summary }}</span>
      </v-tooltip>
    </template>

    <template #[`item.MemberId_Email`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.MemberId_Email }}</div>
        </template>
        <span>{{ item.MemberId_Email }}</span>
      </v-tooltip>
    </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <Can I="update" on="Orders">
          <v-btn small color="success" @click="acknowledge(item)">Acknowledge</v-btn>
          </Can>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiBookEditOutline,
  mdiCog
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'
import { Can } from '@casl/vue'

export default {
   props: {
    title: {
      required: false,
      default: "Unheld Orders"
    },
    defaultFilter: {
      type: Object,
      required: false,
      default: null
    },
    hideFilter: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    OrderStatusChip,
    Can
  },
  beforeDestroy() {
    console.log("beforeDestroy", this.interval);

    clearInterval(this.interval);
  },
  setup(props) {

    const { title, defaultFilter, hideFilter } = toRefs(props);

  // define cols
  const tableColumns = [
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },{ text: 'Reference', value: 'Reference' },
		{ text: 'Status', value: 'StatusId_Name' },
		{ text: 'Updated', value: 'Updated' },
		{ text: 'Product', value: 'ProductId_Name' },
		{ text: 'Member', value: 'MemberId_Reference' },
		{ text: 'Member Email', value: 'MemberId_Email' },
		{ text: 'Delivery', value: 'DeliveryAddressId_Summary' },
		{ text: 'Ret. Ref.', value: 'RetailerReference' },
  ];

  // threee params for fetching
  let search = ref({});
  let filter = ref({
    Unheld: true
  });

  const options = ref({
    sortBy: ['Updated'],
    sortDesc: [true],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Orders || [])
  const totalItems = computed(() => store.state.app.OrdersTotal || 0)
  const selectedItems = ref([])
  const loading = ref(false)


  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchOrders', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }


  const interval = ref(null);
  interval.value = setInterval(() => {
    console.log("INTERVAL")
      fetchData()
    }, 10000)

  const acknowledge = (item) => {

    store
      .dispatch('app/acknowledgeOrder', {
        order: item.Id
      })
       .then(response => {
        fetchData();
       })
      .catch(error => {
        console.log(error)
      })
    }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });



    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'order-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'order-edit' }
    ]

    return {
      interval,
      acknowledge,
      tableColumns,
      search,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,
      Validators,
      Display,
      icons: {
        mdiPlus,
        mdiBookEditOutline,
        mdiCog
      },
    }
  }
}</script>

<style lang="scss" scoped>

</style>
