var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"order-list"}},[(_vm.title)?_c('v-card-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!_vm.hideFilter)?_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"}):_vm._e(),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Reference",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-view', params: { id: item.Id } }}},[_vm._v(" "+_vm._s(item.Reference)+" ")])]}},{key:"item.MemberId_Reference",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'member-view', params: { id: item.MemberId } }}},[_vm._v(" "+_vm._s(item.MemberId_Reference)+" ")])]}},{key:"item.StatusId_Name",fn:function(ref){
var item = ref.item;
return [_c('order-status-chip',{attrs:{"status":item.StatusId_Name}})]}},{key:"item.ProductId_Name",fn:function(ref){
var item = ref.item;
return [(item.BespokePrice)?_c('div',[_c('v-chip',{staticClass:"v-chip-light-bg",attrs:{"title":"Custom Shipment","small":"","color":"info","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.icons.mdiCog))]),_vm._v(" "+_vm._s(item.CustomLength)+"x"+_vm._s(item.CustomWidth)+"x"+_vm._s(item.CustomHeight)+" mm ")],1)],1):(item.ProductId_Name)?_c('div',[_vm._v(" "+_vm._s(item.ProductId_Code)+" - "+_vm._s(item.ProductId_Name)+" ")]):_c('div',{staticClass:"caption d-flex"},[_c('v-chip',{staticClass:"v-chip-light-bg",attrs:{"title":"Quote","small":"","color":"warning","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.icons.mdiBookEditOutline))]),_vm._v(" "+_vm._s(item.CustomLength)+"x"+_vm._s(item.CustomWidth)+"x"+_vm._s(item.CustomHeight)+" mm ")],1)],1)]}},{key:"item.Updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DateTime(item.Updated))+" ")]}},{key:"item.DeliveryAddressId_Summary",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"180px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.DeliveryAddressId_Summary))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.DeliveryAddressId_Summary))])])]}},{key:"item.MemberId_Email",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"180px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.MemberId_Email))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.MemberId_Email))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('Can',{attrs:{"I":"update","on":"Orders"}},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.acknowledge(item)}}},[_vm._v("Acknowledge")])],1)],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }