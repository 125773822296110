<template>
  <v-row>
    <v-col cols="12" md="7">
      <dashboard-arrivals></dashboard-arrivals>
    </v-col>
    <v-col cols="12" md="5">
      <dashboard-issues></dashboard-issues>
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-manifest location="Jersey"></dashboard-manifest>
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-manifest location="Guernsey"></dashboard-manifest>
    </v-col>
    <v-col cols="12" md="12">
      <unheld-list></unheld-list>
    </v-col>
  </v-row>
</template>

<script>
import DashboardArrivals from '@/views/dashboard/components/DashboardArrivals'
import DashboardIssues from '@/views/dashboard/components/DashboardIssues'
import DashboardManifest from '@/views/dashboard/components/DashboardManifest'
import UnheldList from '@/views/order/UnheldList'

export default {
  components: {
    DashboardArrivals,
    DashboardIssues,
    DashboardManifest,
    UnheldList
  },
  setup() {
    return {
    }
  },
}
</script>
